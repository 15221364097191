import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { Container } from '@ttstr/components';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Row>
        <Col md="4">
          <h5 className="mb-3">{t(`FOOTER.HEAD1`)}</h5>
          <ul className="list-unstyled">
            <li>
              <Link to="/imprint">{t(`IMPRINT.TITLE`)}</Link>
            </li>
            <li>
              <Link to="/privacy">{t(`PRIVACY.TITLE`)}</Link>
            </li>
            <li>
              <Link to="/terms">{t(`TERMS_AND_CONDITIONS.TITLE`)}</Link>
            </li>
            <li>
              <a href="https://tickettoaster.de/retouren">Informationen zu Versand und Rückgabe</a>
            </li>
          </ul>
        </Col>
        <Col md="4">
          <h5 className="mb-3">{t(`FOOTER.HEAD2`)}</h5>
          <p>Bei Fragen zu deiner Bestellung wende dich bitte an den Kundenservice der tickettoaster GmbH unter:</p>
          <p className="font-weight-bolder">
            Tel.: +49 561 350 296 28 - 0<br />
            <a href="mailto:hallo@tickettoaster.de">hallo@tickettoaster.de</a>
          </p>
        </Col>
        <Col md="4" className="text-sm-right"></Col>
      </Row>
      <Row className="mt-5 d-flex align-items-center">
        <Col md="8" className="align-items-end mt-4 text-left text-muted copyright">
          <p className="mb-0">
            &copy; 2021 <a href="www.gemischteshack.de">GEMISCHTES HACK</a>
            <br />
            powered by <a href="https://www.tickettoaster.de">tickettoaster Direct-To-Fan Commerce</a>
          </p>
        </Col>
        <Col md="4" className="d-flex align-items-end justify-content-between mt-4 copyright-logos">
          <a href="https://tickettoaster.de" target="_blank" rel="noreferrer noopener">
            <img
              src={require('@ttstr/assets/images/tt_logo_claim-black.svg')}
              alt="powered by tickettoaster"
              title="powered by tickettoaster"
              loading="lazy"
              className="img-fluid"
            />
          </a>
          <a href="https://www.12k.de" target="_blank" className="" rel="noreferrer noopener">
            <img
              src={require('@ttstr/assets/images/tmc_logo_black.png')}
              alt="powered by ZWOELFK"
              title="powered by ZWOELFK"
              loading="lazy"
              className="img-fluid"
            />
          </a>
          <a href="https://www.felixlobrecht-tickets.de" target="_blank" className="" rel="noreferrer noopener">
            <img
              src={require('./assets/images/logo.png')}
              alt="powered by FELIX LOBRECHT"
              title="powered by FELIX LOBRECHT"
              loading="lazy"
              className="img-fluid"
            />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(Footer);
