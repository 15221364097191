import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { G8Manager } from './G8Manager';

const G8Page: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="g8-page-content text-center">
      <Helmet>
        <title>{t('G8.TITLE')}</title>
        <body className="g8-page h-100" />
        {/* <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
          rel="stylesheet"
          integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
          crossOrigin="anonymous"
        /> */}
      </Helmet>
      <div className="main-wrapper container h-100 w-100 d-flex justify-content-center align-items-center flex-column">
        <div className="col-12 col-sm-12 col-md-8 col-lg-6 p-3">
          <div className="logo-wrapper mb-4">
            <img src={require('./assets/images/logo.svg')} alt="GEMISCHTES HACK" className="logo img-fluid" />
          </div>
          {/* <!-- COUNTDOWN / PHASE 1 --> */}
          {/* <div className="countdown-wrapper d-none">
            <div className="card">
              <div className="card-body text-center shadow-lg p-4">
                <h1 className="mb-0 headline">
                  Tickets in DD:MM:HH:SS
                </h1>
              </div>
            </div>
          </div> */}
          {/* <!-- WAIT / PHASE 2 --> */}
          {/* <div className="wait-wrapper">
            <div className="card ">
              <div className="card-body text-center shadow-lg p-4">
                <p className="lead">Ooops, gerade ist viel los! Wir bitten dich um ein wenig Geduld bis Du auf den Shop weitergeleitet wirst.</p>
                <h1 className="mb-0 headline">
                  Du bist an Position<br />
                  <span className="gate-position badge bg-danger mt-4">9999</span>
                </h1>
              </div>
            </div>
            <div className="m-4 text-center small">
              <strong>Lade diese Seite nicht neu oder schicke deinen Browser in den Hintergrund!</strong> Sonst wird deine Position frei und Du wirst wieder hinten angestellt.
            </div>
          </div> */}
          {/* <!-- GOOOOO / PHASE 3 --> */}
          {/* <div className="go-wrapper p-4 d-none">
              <a href="#" className="btn btn-primary btn-lg d-block" tabIndex="-1" role="button" aria-disabled="true">Jetzt Tickets kaufen</a>
          </div> */}
          {/* React G8 Manager */}
          <G8Manager />
        </div>
      </div>
      {/* <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
        crossOrigin="anonymous"
      ></script> */}
    </div>
  );
};

export default React.memo(G8Page);
